//------------------------------------------------------------------------
// Modal windows
//------------------------------------------------------------------------
import Modal from "../lib/modals";

class ExplainerModal {
  constructor(el) {
    // NOTE: Consider moving this to a global var since it’s shared across scripts
    this.activeClass = "is-stuck";
    this.closeLinks = document.querySelectorAll(".Explainer-cta-close");
    // Note: Ignore first child since we’re manually adding “is-stuck” class to prevent a FOUC
    this.slides = document.querySelectorAll(".Explainer-slide:not(:first-child)");
    this.modal = new Modal(el);

    this.modal.on("close", () => {
      this.reset();
    });

    // Close modal and scroll to target element (based on hash in href)
    this.closeLinks.forEach(el => {
      el.addEventListener("click", evt => {
        evt.preventDefault();
        this.modal.close(evt);

        let targetEl = evt.currentTarget;
        let scrollTarget = document.querySelector(targetEl.hash);

        if (scrollTarget) {
          // Use setTimeout since scrolling will still be disabled by the modal
          setTimeout(() => {
            scrollTarget.scrollIntoView();
          }, 100);
        }
      });
    });
  }

  // Reset explainer to first slide (remove active classes)
  reset() {
    this.slides.forEach(el => {
      el.classList.remove(this.activeClass);
    });
  }
}

const el = document.querySelector('.Explainer');

if (el) {
  let modal = new ExplainerModal(el);
}
