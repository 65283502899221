//------------------------------------------------------------------------
// Jump link navigation (used on disease pages and news landing)
//------------------------------------------------------------------------
"use strict";

import ExpandToggle from "@threespot/expand-toggle";

class ExplainerMobileNav {
  constructor(el) {
    this.el = el;
    // Breakpoint at which to init JS (must match $layout-explainer-wide-bp in _layout-vars.scss)
    this.mediaQueryList = window.matchMedia("(max-width: 899px)");
    this.contentEl = document.getElementById(this.el.getAttribute("data-expands"));
    // Determine if touch events are supported
    this.eventType = "ontouchend" in window ? "touchend" : "click";
    // Use separate var for click handler so we can remove it
    this.windowClick = this.windowClickHandler.bind(this);

    // Listen for breakpoint change
    this.mediaQueryList.addListener(evt => {
      if (evt.matches) {
        this.init();
      } else {
        this.destroy();
      }
    });

    // Init on load
    if (this.mediaQueryList.matches) {
      this.init();
    }
  }

  windowClickHandler(evt) {
    if (!evt.target.closest(".ExplainerNavMobile-toggle")) {
      this.toggleExpand.collapse();
    }
  }

  init() {
    this.toggleExpand = new ExpandToggle(this.el);
    window.addEventListener(this.eventType, this.windowClick);
  }

  destroy() {
    this.toggleExpand.destroy();
    window.removeEventListener(this.eventType, this.windowClick);
  }
}

// Init
document.querySelectorAll('.ExplainerNavMobile-toggle[data-expands]').forEach(el => new ExplainerMobileNav(el));
