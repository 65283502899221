//------------------------------------------------------------------------
// Trigger crossfade on scroll in explainer component
//
// https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver
// https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
// https://css-tricks.com/a-few-functional-uses-for-intersection-observer-to-know-when-an-element-is-in-view/
// https://alligator.io/js/intersection-observer/
//------------------------------------------------------------------------
// Polyfill (included here instead of polyfills.js since we need it in Safari 12/iOS 12.1)
// https://github.com/w3c/IntersectionObserver/tree/master/polyfill
// https://caniuse.com/#feat=intersectionobserver
import "intersection-observer";
// import debounce from "lodash/debounce";
import throttle from "lodash/throttle";

class Explainer {
  constructor(slides) {
    this.slides = slides;
    this.modalContent = document.querySelector(".Explainer-slides");
    this.desktopNavLinks = document.querySelectorAll(".ExplainerNavDesktop-link");

    // Get elements to watch on scroll
    this.watchEls = [];
    this.slides.forEach(el => {
      let scrollContent = el.querySelector(".Explainer-slide-content");
      if (scrollContent) {
        this.watchEls.push(scrollContent);
      }
    });

    // Breakpoint at which to init JS (must match $layout-explainer-wide-bp in _layout-vars.scss)
    this.mediaQueryList = window.matchMedia("(min-width: 900px)");

    // Class name to toggle when element is visible in viewport
    this.stickyClass = "is-stuck";

    // Class name to toggle on nav links
    this.activeClass = "is-active";

    // Element height percentage visible at which point to show/hide the current slide
    // https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Thresholds
    this.threshold = [0.2, 0.8];

    // Placeholder for element y-offset values to determine scroll direction
    this.yOffsets = {};

    // Create new IntersectionObserver
    this.observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach(entry => {
          this.observerHandler(entry);
        });
      }, { threshold: this.threshold }
    );

    // Listen for breakpoint change
    this.mediaQueryList.addListener(evt => {
      if (evt.matches) {
        this.init();
      } else {
        this.destroy();
      }
    });

    // Init on load
    if (this.mediaQueryList.matches) {
      this.init();
    }

    // Listen to scroll event so we can disable pointer events on modal toggles
    let self = this;
    window.addEventListener(
      "wheel",
      throttle(function(event) {
        self.disableHover(event);
      }, 50),
      true
    );
  }

  disableHover(event) {
    clearTimeout(this.scrollTimer);
    this.modalContent.classList.add('disable-hover');

    this.scrollTimer = setTimeout( () => {
      this.modalContent.classList.remove('disable-hover');
    }, 50);
  }

  reset() {
    // Scroll back to top since the logic only works when scrolling from the top
    this.modalContent.scrollTop = 0;

    // Remove active classes
    this.slides.forEach(el => {
      el.classList.remove(this.stickyClass);
    });

    for (var i=0, len=this.desktopNavLinks.length; i<len; i++) {
      let el = this.desktopNavLinks[i];
      el.classList.toggle(this.activeClass, i === 0);
    }
  }

  init() {
    // IntersectionObserver is buggy on window resize so manually reset
    // https://github.com/w3c/IntersectionObserver/issues/311
    this.reset();

    this.watchEls.forEach(el => {
      this.observer.observe(el);
    });
  }

  destroy() {
    // Disconnect all watchers
    this.observer.disconnect();
    this.reset();
  }

  observerHandler(entry) {
    let title = "“" + entry.target.innerText.substring(0, 20) + "…”";
    let scrollDirection = null;

    // Determine scroll direction by comparing current y-offset to previous value
    // Note: This requires adding a unique ID to each target element so we can reference it.
    if (this.yOffsets[entry.target.id]) {
      scrollDirection = entry.boundingClientRect.top < this.yOffsets[entry.target.id] ? "down" : "up";
    }

    // Update current y-offset value
    this.yOffsets[entry.target.id] = entry.boundingClientRect.top;

    // Instead of toggling the “is-stuck” class on just the active slide,
    // we’re leaving it on previously active slides when scrolling down.
    // When scrolling up, we’re removing it as each slide exits the viewport.
    // This prevent a flash of the first slide’s background on crossfade
    // when a slide fades out before the next one fades in, which can happen
    // if the slide contains very little content.

    // NOTE: If a users scrolls up and down VERY quickly in rapid succession,
    // it’s possible for the observer to miss an event, causing the wrong
    // background image to show. This could be fixed by updating ALL slide
    // classes each time an observer fires, but that may hurt performance.

    // If event fires while scrolling down, show if element if not cutoff on top
    // (if it’s touching the top it should already be visible)
    if (scrollDirection == "down" && entry.intersectionRect.top !== 0) {
      // console.log("ENTER:", title);
      let slideEl = entry.target.closest(".Explainer-slide");
      slideEl.classList.add(this.stickyClass);
      // Update active nav link class
      this.updateDesktopNav(slideEl.id, "down");
    }
    // If event fires while scrolling up, hide element if not cutoff on top
    // (if it’s touching the top we want it to be visible)
    else if (scrollDirection == "up" && entry.intersectionRect.top !== 0) {
      let slideEl = entry.target.closest(".Explainer-slide");
      // console.log("EXIT:", title);
      slideEl.classList.remove(this.stickyClass);
      // Update active nav link class
      this.updateDesktopNav(slideEl.id, "up");
    }
  }

  updateDesktopNav(id, scrollDirection) {
    let activeId = id;

    // When scrolling up, the id refers to the slide that just left the viewport,
    // so we need to subtract 1 to get the currently active slide.
    if (scrollDirection == "up") {
      let idArray = activeId.split("_");
      activeId = idArray[0] + "_" + (parseInt(idArray[1], 10) - 1);
    }

    this.desktopNavLinks.forEach(el => {
      // Check if link “href” matches active slide “id”
      el.classList.toggle(this.activeClass, el.hash == "#" + activeId);
    });
  }
}

// Init
// Note: Ignore first child since we’re manually adding “is-stuck” class to prevent a FOUC
const slides = document.querySelectorAll('.Explainer-slide:not(:first-child)');

if (slides.length) {
  new Explainer(slides);
}
